import * as api from "../data/api";
import Immutable from "immutable";
import { getStorageValue, removeStorageValue, setStorageValue } from "./storage";
import { CACHE_PERIOD_COMMON_CONFIG, LOAD_COMMON_CONFIG, LOAD_COMMON_CONFIG_DONE, LOAD_COMMON_CONFIG_ERROR, CLEAR_COMMON_CONFIG, RESET_COMMON_CONFIG } from "./constants";
import { isNotCached } from "./config";
const app = "admin";
const source = "common/config";
const prefix = "common-config.";
const defaultState = {
  genders: getStorageValue("genders", {}, prefix),
  lastFetch: getStorageValue("lastFetch", null, prefix),
  loading: false,
  error: null
};
const initialState = Immutable.Map(defaultState);
function buildCommonConfig(data) {
  let config = {
    genders: {}
  };
  if (data["genders"]) {
    data["genders"].forEach(gender => {
      config["genders"][gender["id"]] = gender;
    });
  }
  return config;
}
export const getCommonConfig = () => (dispatch, getState) => {
  const loading = getState().getIn(["common-config", "loading"]);
  const lastFetch = getState().getIn(["common-config", "lastFetch"]);
  const genders = getState().getIn(["common-config", "genders"]);
  if (!genders || isNotCached(lastFetch, CACHE_PERIOD_COMMON_CONFIG)) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COMMON_CONFIG
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      lang: locale
    };
    api.getData({
      app,
      source: source,
      params
    }).then(data => dispatch({
      type: LOAD_COMMON_CONFIG_DONE,
      data: {
        config: buildCommonConfig(data)
      }
    })).catch(error => dispatch({
      type: LOAD_COMMON_CONFIG_ERROR,
      error
    }));
  }
};
export const clearCommonConfig = () => dispatch => {
  dispatch({
    type: CLEAR_COMMON_CONFIG
  });
};
export const resetCommonConfig = () => dispatch => {
  dispatch({
    type: RESET_COMMON_CONFIG
  });
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMMON_CONFIG:
      {
        return state.set("loading", true).set("error", null);
      }
    case LOAD_COMMON_CONFIG_ERROR:
      {
        const {
          error
        } = action;
        return state.set("loading", false).set("error", error);
      }
    case LOAD_COMMON_CONFIG_DONE:
      {
        const {
          data
        } = action;
        Object.keys(data["config"]).forEach(key => {
          let merged = {
            ...state.get(key),
            ...data["config"][key]
          };
          if (!(Symbol.iterator in Object(data["config"][key]))) {
            merged = data["config"][key];
          }
          setStorageValue(key, merged, prefix);
        });
        setStorageValue("lastFetch", new Date(), prefix);
        return Immutable.Map({
          ...state.toJS(),
          ...data["config"],
          lastFetch: new Date(),
          loading: false,
          error: null
        });
      }
    case CLEAR_COMMON_CONFIG:
      {
        removeStorageValue("genders", prefix);
        removeStorageValue("lastFetch", prefix);
        return state.set("genders", {}).set("lastFetch", null).set("loading", false).set("error", null);
      }
    case RESET_COMMON_CONFIG:
      {
        setStorageValue("lastFetch", null);
        return state.set("lastFetch", null);
      }
    default:
      return state;
  }
};