import * as api from "../data/api";
import Immutable from "immutable";
import { colorSchemes } from "@nivo/colors";
import { getStorageValue, removeStorageValue, setStorageValue } from "./storage";
import { CACHE_PERIOD_APP_CONFIG, LOAD_APP_CONFIG, LOAD_APP_CONFIG_DONE, LOAD_APP_CONFIG_ERROR, CLEAR_APP_CONFIG, RESET_APP_CONFIG, LOAD_COUNTRY_CONFIG, LOAD_COUNTRY_CONFIG_DONE, LOAD_COUNTRY_CONFIG_ERROR, RESET_COUNTRY_CONFIG, LOAD_COUNTRY_PRODUCTION_ALL, LOAD_COUNTRY_PRODUCTION_ALL_DONE, LOAD_COUNTRY_PRODUCTION_ALL_ERROR, RESET_COUNTRY_PRODUCTION_ALL, LOAD_COUNTRY_BOUNDARY_PRIMARY, LOAD_COUNTRY_BOUNDARY_PRIMARY_DONE, LOAD_COUNTRY_BOUNDARY_PRIMARY_ERROR, RESET_COUNTRY_BOUNDARY_PRIMARY, LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW, LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_DONE, LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_ERROR, RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_DONE, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_ERROR, RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_DONE, LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_ERROR, RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND, LOAD_COUNTRY_PRODUCTION_AVG_YIELD, LOAD_COUNTRY_PRODUCTION_AVG_YIELD_DONE, LOAD_COUNTRY_PRODUCTION_AVG_YIELD_ERROR, RESET_COUNTRY_PRODUCTION_AVG_YIELD, LOAD_COUNTRY_PRODUCTION_VOLUME, LOAD_COUNTRY_PRODUCTION_VOLUME_DONE, LOAD_COUNTRY_PRODUCTION_VOLUME_ERROR, RESET_COUNTRY_PRODUCTION_VOLUME, LOAD_COUNTRY_PRODUCTION_COSTS, LOAD_COUNTRY_PRODUCTION_COSTS_DONE, LOAD_COUNTRY_PRODUCTION_COSTS_ERROR, RESET_COUNTRY_PRODUCTION_COSTS, LOAD_RESOURCE_CONFIG, LOAD_RESOURCE_CONFIG_DONE, LOAD_RESOURCE_CONFIG_ERROR, RESET_RESOURCE_CONFIG, LOAD_GLOBAL_PRODUCTION_VOLUME, LOAD_GLOBAL_PRODUCTION_VOLUME_DONE, LOAD_GLOBAL_PRODUCTION_VOLUME_ERROR, RESET_GLOBAL_PRODUCTION_VOLUME, SET_CONFIG } from "./constants";
import { isNotCached } from "./config";
const CACHE_PERIOD = CACHE_PERIOD_APP_CONFIG;
const app = "admin";
const source = {
  appConfig: "app/config",
  countryConfig: "country/config",
  countryBoundaryPrimary: "country/boundary/primary",
  countryProductionAll: "country/production/all",
  countryProductionFarmStatsCampaignFlow: "country/production/farm-stats/campaign-flow",
  countryProductionFarmStatsFarmPriceVariation: "country/production/farm-stats/farm-price-variation",
  countryProductionFarmStatsFarmLand: "country/production/farm-stats/farm-land",
  countryProductionAvgYield: "country/production/avg-yield",
  countryProductionVolume: "country/production/volume",
  countryProductionCosts: "country/production/costs",
  globalProductionVolume: "global/production/volume",
  resourceConfig: "resource/config"
};
const prefix = "app-config.";
const defaultState = {
  countries: getStorageValue("countries", {}, prefix),
  syntheticCategories: getStorageValue("syntheticCategories", {}, prefix),
  regions: getStorageValue("regions", [], prefix),
  hemispheres: getStorageValue("hemispheres", [], prefix),
  priceTypes: getStorageValue("priceTypes", [], prefix),
  productTypes: getStorageValue("productTypes", [], prefix),
  kernelQualities: getStorageValue("kernelQualities", [], prefix),
  kernelQualitySubTypes: getStorageValue("kernelQualitySubTypes", [], prefix),
  currencies: getStorageValue("currencies", [], prefix),
  startingYear: getStorageValue("startingYear", null, prefix),
  lastFetch: getStorageValue("lastFetch", null, prefix),
  usedCurrencies: getStorageValue("usedCurrencies", null, prefix),
  loading: false,
  error: null
};
const initialState = Immutable.Map(defaultState);
const getAllChildren = (data, parentId, level = null) => {
  const children = [];
  const findChildren = (parentId, level) => {
    Object.keys(data).forEach(item => {
      if (parseInt(data[item].id) === parseInt(parentId) && data[item]['primaryChildren'] !== undefined) {
        if (level === null || level !== null && parseInt(data[item]['level']) === level) {
          children.push(...data[item]['primaryChildren']);
        }
        data[item]['primaryChildren'].forEach(childId => {
          findChildren(childId, level);
        });
      }
    });
  };
  findChildren(parentId, level);
  return children;
};
const getAllParents = (data, childId, level = null) => {
  const parents = [];
  const findParents = (childId, level) => {
    const parentLevel = parseInt(data[childId]['level']) - 1;
    const levelProperty = 'level' + parentLevel + 'Id';
    if (data[childId][levelProperty] !== undefined) {
      if (level === null || level !== null && parentLevel === level) {
        parents.push(data[childId][levelProperty]);
      }
      findParents(data[childId][levelProperty]);
    }
  };
  findParents(childId, level);
  return parents;
};
function buildAppConfig(data) {
  let config = {
    startingYear: data["settings"] && data["settings"]["startingYear"] ? data["settings"]["startingYear"] : null,
    countries: {},
    syntheticCategories: data.syntheticCategories || {},
    regions: data.regions || [],
    hemispheres: data.hemispheres || [],
    priceTypes: data.priceTypes || [],
    productTypes: data.productTypes || [],
    kernelQualities: data.kernelQualities || [],
    kernelQualitySubTypes: data.kernelQualitySubTypes || [],
    currencies: data.currencies || [],
    usedCurrencies: data.usedCurrencies || []
  };
  if (data["countries"]) {
    for (const countryKey in data["countries"]) {
      let countrySetting = {};
      if (data["countrySettings"]) {
        for (const settingKey in data["countrySettings"]) {
          if (data["countrySettings"][settingKey]["countryId"] === data["countries"][countryKey]["id"]) {
            countrySetting = data["countrySettings"][settingKey];
            break;
          }
        }
      }
      config["countries"][data["countries"][countryKey]["iso3"]] = {
        ...countrySetting,
        ...data["countries"][countryKey]
      };
    }
  }
  return config;
}
function buildCountryConfig(data) {
  let config = {
    levels: {},
    locations: {}
  };
  if (data["primaryAdmLevels"]) {
    data["primaryAdmLevels"].forEach(level => {
      config["levels"][level["level"]] = level;
      if (level["admLocations"]) {
        level["admLocations"].forEach(location => {
          config["locations"][location] = {
            ...config["locations"][location],
            level: level["level"]
          };
        });
      }
    });
  }
  if (data["admLocations"]) {
    data["admLocations"].forEach(location => {
      config["locations"][location["id"]] = {
        ...config["locations"][location["id"]],
        ...location
      };
    });
  }
  Object.keys(config["locations"]).forEach(locationId => {
    if (config["locations"][locationId]["primaryChildren"]) {
      config["locations"][locationId]["primaryChildren"].forEach(childId => {
        config["locations"][childId]["level" + config["locations"][locationId]["level"] + "Id"] = parseInt(locationId);
        Object.values(config.levels).forEach(level => {
          if (config["locations"][locationId]["level" + level.level + "Id"]) {
            config["locations"][childId]["level" + level.level + "Id"] = config["locations"][locationId]["level" + level.level + "Id"];
          }
        });
      });
    }
  });
  Object.keys(config["locations"]).forEach(locationId => {
    const children = getAllChildren(config["locations"], locationId);
    const parents = getAllParents(config["locations"], locationId);
    let levelInfo = {};
    Object.keys(config["levels"]).map(key => config["levels"][key]['level']).forEach(level => {
      levelInfo[`level${level}Children`] = getAllChildren(config["locations"], locationId, level);
      levelInfo[`level${level}Parents`] = getAllParents(config["locations"], locationId, level);
    });
    config["locations"][locationId] = {
      ...config["locations"][locationId],
      ...levelInfo,
      allChildren: children,
      allChildrenCount: children.length,
      allParents: parents,
      allParentsCount: parents.length
    };
  });
  return config;
}
function buildCountryBoundaryPrimary(data, level = 1) {
  let config = data;
  let locationIds;
  const levelName = `adm${level}`;
  const levelNameLabel = `adm${level}_name`;
  if (config[levelName]["features"] && config["levels"][level] && config["levels"][level]["admLocations"]) {
    locationIds = config["levels"][level]["admLocations"] || [];
    Object.keys(config[levelName]["features"]).forEach(featureId => {
      if (config[levelName]["features"][featureId]["properties"] && config[levelName]["features"][featureId]["properties"][levelNameLabel]) {
        config["levels"][level]["admLocations"].forEach(locationId => {
          if (config["locations"][locationId] && config["locations"][locationId]["label"] && config["locations"][locationId]["label"] === config[levelName]["features"][featureId]["properties"][levelNameLabel]) {
            locationIds = locationIds.filter(l => l !== locationId);
            config[levelName]["features"][featureId]["properties"] = {
              ...config[levelName]["features"][featureId]["properties"],
              id: locationId
            };
            config["locations"][locationId]["feature"] = {
              ...config["locations"][locationId]["feature"],
              ...config[levelName]["features"][featureId]
            };
          }
        });
      }
    });
    if (locationIds.length) {
      locationIds.forEach(l => console.warn('No match for ' + config["locations"][l]["label"]));
    }
  }
  return config;
}
function buildCountryProductionAll(data) {
  let config = {
    certificateTypes: {},
    farmAssociations: {}
  };
  if (data["commonConfig"] && data["commonConfig"]["certificateTypes"]) {
    data["commonConfig"]["certificateTypes"].forEach(type => {
      config["certificateTypes"][type["id"]] = type;
    });
  }
  if (data["countryConfig"] && data["countryConfig"]["farmAssociations"]) {
    data["countryConfig"]["farmAssociations"].forEach(association => {
      config["farmAssociations"][association["id"]] = association;
    });
  }
  return config;
}
function buildCountryProductionCosts(data) {
  let config = {
    costTypes: {}
  };
  if (data["config"] && data["config"]["costTypes"]) {
    data["config"]["costTypes"].forEach((type, key) => {
      let children = [];
      if (type["children"]) {
        type["children"].forEach(child => {
          children.push(child["id"]);
          config["costTypes"][child["id"]] = {
            ...child,
            costTypeId: type["id"],
            color: colorSchemes['category10'][key]
          };
        });
      }
      config["costTypes"][type["id"]] = {
        ...type,
        children,
        color: colorSchemes['category10'][key]
      };
    });
  }
  data["config"] = {
    ...data["config"],
    ...config
  };
  return data;
}
export const getAppConfig = () => (dispatch, getState) => {
  const loading = initialState.getIn(["loading"]);
  const lastFetch = initialState.getIn(["lastFetch"]);
  const countries = initialState.getIn(["countries"]);
  const syntheticCategories = initialState.getIn(["syntheticCategories"]);
  const regions = initialState.getIn(["regions"]);
  const hemispheres = initialState.getIn(["hemispheres"]);
  const priceTypes = initialState.getIn(["priceTypes"]);
  const productTypes = initialState.getIn(["productTypes"]);
  const kernelQualities = initialState.getIn(["kernelQualities"]);
  const kernelQualitySubTypes = initialState.getIn(["kernelQualitySubTypes"]);
  const currencies = initialState.getIn(["currencies"]);
  const startingYear = initialState.getIn(["startingYear"]);
  const usedCurrencies = initialState.getIn(["usedCurrencies"]);
  if (!countries || !startingYear || !usedCurrencies || !syntheticCategories || !regions || !hemispheres || !priceTypes || !productTypes || !kernelQualities || !kernelQualitySubTypes || currencies || isNotCached(lastFetch, CACHE_PERIOD)) {
    if (loading === true) return;
    dispatch({
      type: LOAD_APP_CONFIG
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      lang: locale
    };
    api.getData({
      app,
      source: source.appConfig,
      params
    }).then(data => {
      dispatch({
        type: LOAD_APP_CONFIG_DONE,
        data: {
          config: buildAppConfig(data)
        }
      });
      const id = data.countries.find(i => i.iso3 === getState().getIn(["config", "country"])).id;
      const currencyCode = data.countrySettings.find(i => i.countryId === id).currencyCode;
      dispatch({
        type: SET_CONFIG,
        config: "currencyCode",
        value: currencyCode
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      error
    }));
  }
};
export const resetAppConfig = () => dispatch => {
  dispatch({
    type: RESET_APP_CONFIG
  });
};
export const getCountryConfig = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryConfig", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryConfig", "lastFetch"]);
  const levels = initialState.getIn(["countries", country, "levels"]);
  const locations = initialState.getIn(["countries", country, "locations"]);
  if (country && (!levels || !locations || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_CONFIG,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.countryConfig,
      params
    }).then(data => {
      dispatch({
        type: LOAD_COUNTRY_CONFIG_DONE,
        data: {
          country,
          config: buildCountryConfig(data)
        }
      });
    }).catch(error => dispatch({
      type: LOAD_COUNTRY_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryConfig = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_CONFIG,
    data: {
      country
    }
  });
};
export const getCountryBoundaryPrimary = (country, level = 1) => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryBoundaryPrimary", level, "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryBoundaryPrimary", level, "lastFetch"]);
  const admName = `adm${level}`;
  const adm = initialState.getIn(["countries", country, admName]);
  if (country && (!adm || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    const levels = initialState.getIn(["countries", country, "levels"]);
    const locations = initialState.getIn(["countries", country, "locations"]);
    if (!levels || !locations) return;
    dispatch({
      type: LOAD_COUNTRY_BOUNDARY_PRIMARY,
      data: {
        level,
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.countryBoundaryPrimary,
      params: {
        ...params,
        admLevel: level
      }
    }).then(data => {
      let response = {
        levels,
        locations
      };
      response[admName] = data;
      dispatch({
        type: LOAD_COUNTRY_BOUNDARY_PRIMARY_DONE,
        data: {
          level,
          country,
          config: buildCountryBoundaryPrimary(response, level)
        }
      });
    }).catch(error => {
      dispatch({
        type: LOAD_COUNTRY_BOUNDARY_PRIMARY_ERROR,
        data: {
          level,
          country
        },
        error
      });
    });
  }
};
export const resetCountryBoundaryPrimary = (country, level = 1) => dispatch => {
  dispatch({
    type: RESET_COUNTRY_BOUNDARY_PRIMARY,
    data: {
      country,
      level
    }
  });
};
export const getCountryProductionAll = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionAll", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionAll", "lastFetch"]);
  const certificateTypes = initialState.getIn(["countries", country, "certificateTypes"]);
  const farmAssociations = initialState.getIn(["countries", country, "farmAssociations"]);
  if (country && (!certificateTypes || !farmAssociations || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_ALL,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.countryProductionAll,
      params
    }).then(data => dispatch({
      type: LOAD_COUNTRY_PRODUCTION_ALL_DONE,
      data: {
        country,
        config: buildCountryProductionAll(data)
      }
    })).catch(error => dispatch({
      type: LOAD_COUNTRY_PRODUCTION_ALL_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionAll = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_ALL,
    data: {
      country
    }
  });
};
export const getCountryProductionFarmStatsCampaignFlow = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "lastFetch"]);
  const adm1Ids = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "config", "adm1Ids"]);
  const years = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "config", "years"]);
  const kor = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "config", "kor"]);
  const humidity = initialState.getIn(["countries", country, "countryProductionFarmStatsCampaignFlow", "config", "humidity"]);
  const currencyCode = initialState.getIn(["countries", country, "currencyCode"]);
  if (country && (!adm1Ids || !years || !kor || !humidity || !currencyCode || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale,
      currencyCode: currencyCode
    };
    api.getData({
      app,
      source: source.countryProductionFarmStatsCampaignFlow,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_DONE,
        data: {
          country,
          data
        }
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionFarmStatsCampaignFlow = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW,
    data: {
      country
    }
  });
};
export const getCountryProductionFarmStatsFarmPriceVariation = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmPriceVariation", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmPriceVariation", "lastFetch"]);
  const config = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmPriceVariation", "config"]);
  const filter = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmPriceVariation", "filter"]);
  const setting = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmPriceVariation", "setting"]);
  const currencyCode = initialState.getIn(["countries", country, "currencyCode"]);
  if (country && (!config || !filter || !setting || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale,
      currencyCode
    };
    api.getData({
      app,
      source: source.countryProductionFarmStatsFarmPriceVariation,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_DONE,
        data: {
          country,
          data
        }
      });
    }).catch(error => {
      dispatch({
        type: LOAD_APP_CONFIG_ERROR,
        data: {
          country
        },
        error
      });
    });
  }
};
export const resetCountryProductionFarmStatsFarmPriceVariation = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION,
    data: {
      country
    }
  });
};
export const getCountryProductionFarmStatsFarmLand = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmLand", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmLand", "lastFetch"]);
  const densityBreakpoints = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmLand", "setting", "densityBreakpoints"]);
  const ageBreakpoints = initialState.getIn(["countries", country, "countryProductionFarmStatsFarmLand", "setting", "ageBreakpoints"]);
  if (country && (!densityBreakpoints || !ageBreakpoints || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.countryProductionFarmStatsFarmLand,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_DONE,
        data: {
          country,
          data
        }
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionFarmStatsFarmLand = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND,
    data: {
      country
    }
  });
};
export const getCountryProductionAvgYield = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionAvgYield", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionAvgYield", "lastFetch"]);
  const years = initialState.getIn(["countries", country, "countryProductionAvgYield", "config", "years"]);
  const ageBreakpoints = initialState.getIn(["countries", country, "countryProductionAvgYield", "setting", "ageBreakpoints"]);
  if (country && (!years || !ageBreakpoints || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_AVG_YIELD,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.countryProductionAvgYield,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_AVG_YIELD_DONE,
        data: {
          country,
          data
        }
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionAvgYield = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_AVG_YIELD,
    data: {
      country
    }
  });
};
export const getCountryProductionVolume = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionVolume", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionVolume", "lastFetch"]);
  const years = initialState.getIn(["countries", country, "countryProductionVolume", "config", "years"]);
  const currencyCode = initialState.getIn(["countries", country, "currencyCode"]);
  if (country && (!years || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_VOLUME,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale,
      currencyCode
    };
    api.getData({
      app,
      source: source.countryProductionVolume,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_VOLUME_DONE,
        data: {
          country,
          data
        }
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionVolume = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_VOLUME,
    data: {
      country
    }
  });
};
export const getCountryProductionCosts = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "countryProductionCosts", "loading"]);
  const lastFetch = initialState.getIn(["countries", country, "countryProductionCosts", "lastFetch"]);
  const years = initialState.getIn(["countries", country, "countryProductionCosts", "config", "years"]);
  const costTypes = initialState.getIn(["countries", country, "countryProductionCosts", "config", "costTypes"]);
  const currencyCode = initialState.getIn(["countries", country, "currencyCode"]);
  if (country && (!years || !costTypes || isNotCached(lastFetch, CACHE_PERIOD))) {
    if (loading === true) return;
    dispatch({
      type: LOAD_COUNTRY_PRODUCTION_COSTS,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale,
      currencyCode
    };
    api.getData({
      app,
      source: source.countryProductionCosts,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_COUNTRY_PRODUCTION_COSTS_DONE,
        data: {
          country,
          data: buildCountryProductionCosts(data)
        }
      });
    }).catch(error => dispatch({
      type: LOAD_APP_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const resetCountryProductionCosts = country => dispatch => {
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_COSTS,
    data: {
      country
    }
  });
};
function buildResourceConfig(data) {
  let config = {
    subjects: {},
    themes: {},
    categories: {}
  };
  if (data["subjects"]) {
    data["subjects"].forEach(subject => {
      config["subjects"][subject["id"]] = {
        ...subject,
        children: subject["children"] ? subject["children"].map(c => c.id) : []
      };
      if (subject["children"]) {
        subject["children"].forEach(theme => {
          config["themes"][theme["id"]] = {
            ...theme,
            subjectId: subject["id"],
            children: theme["children"] ? theme["children"].map(c => c.id) : []
          };
          if (theme["children"]) {
            theme["children"].forEach(category => {
              config["categories"][category["id"]] = {
                ...category,
                subjectId: subject["id"],
                themeId: theme["id"]
              };
            });
          }
        });
      }
    });
  }
  return config;
}
export const getResourceConfig = country => (dispatch, getState) => {
  const loading = initialState.getIn(["countries", country, "resourceConfig", "loading"]);
  if (country) {
    if (loading === true) return;
    dispatch({
      type: LOAD_RESOURCE_CONFIG,
      data: {
        country
      }
    });
    const locale = getState().getIn(["intl", "locale"]);
    const params = {
      iso3: country,
      lang: locale
    };
    api.getData({
      app,
      source: source.resourceConfig,
      params
    }).then(data => {
      dispatch({
        type: LOAD_RESOURCE_CONFIG_DONE,
        data: {
          country,
          data: buildResourceConfig(data)
        }
      });
    }).catch(error => dispatch({
      type: LOAD_RESOURCE_CONFIG_ERROR,
      data: {
        country
      },
      error
    }));
  }
};
export const getGlobalProductionVolume = () => (dispatch, getState) => {
  const loading = initialState.getIn(["globalProductionVolume", "loading"]);
  const lastFetch = initialState.getIn(["globalProductionVolume", "lastFetch"]);
  const config = initialState.getIn(["globalProductionVolume", "config"]);
  const filter = initialState.getIn(["globalProductionVolume", "filter"]);
  if (!config || !filter || isNotCached(lastFetch, CACHE_PERIOD)) {
    if (loading === true) return;
    dispatch({
      type: LOAD_GLOBAL_PRODUCTION_VOLUME
    });
    const locale = getState().getIn(["intl", "locale"]);
    const currencyCode = getState().getIn(["config", "currencyCode"]);
    const params = {
      lang: locale,
      currencyCode
    };
    api.getData({
      app,
      source: source.globalProductionVolume,
      params
    }).then(data => {
      delete data['data'];
      dispatch({
        type: LOAD_GLOBAL_PRODUCTION_VOLUME_DONE,
        data
      });
    }).catch(error => dispatch({
      type: LOAD_GLOBAL_PRODUCTION_VOLUME_ERROR,
      error
    }));
  }
};
export const resetGlobalProductionVolume = () => dispatch => {
  dispatch({
    type: RESET_GLOBAL_PRODUCTION_VOLUME
  });
};
export const resetResourceConfig = country => dispatch => {
  dispatch({
    type: RESET_RESOURCE_CONFIG,
    data: {
      country
    }
  });
};
export const resetAllCountryConfigs = (country, level = 1) => dispatch => {
  dispatch({
    type: RESET_COUNTRY_CONFIG,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_COUNTRY_BOUNDARY_PRIMARY,
    data: {
      country,
      level
    }
  });
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_ALL,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_COUNTRY_PRODUCTION_AVG_YIELD,
    data: {
      country
    }
  });
  dispatch({
    type: RESET_RESOURCE_CONFIG,
    data: {
      country
    }
  });
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_APP_CONFIG:
      {
        return state.set("error", null).set("loading", true);
      }
    case LOAD_APP_CONFIG_ERROR:
      {
        const {
          error
        } = action;
        return state.set("loading", false).set("error", error);
      }
    case LOAD_APP_CONFIG_DONE:
      {
        const {
          data
        } = action;
        Object.keys(data["config"]).forEach(key => {
          let merged = Array.isArray(data["config"][key]) ? [...data["config"][key]] : {
            ...state.get(key),
            ...data["config"][key]
          };
          if (!(Symbol.iterator in Object(data["config"][key]))) {
            merged = data["config"][key];
          }
          setStorageValue(key, merged, prefix);
        });
        setStorageValue("lastFetch", new Date(), prefix);
        return Immutable.Map({
          ...state.toJS(),
          ...data["config"],
          lastFetch: new Date(),
          loading: false,
          error: null
        });
      }
    case RESET_APP_CONFIG:
      {
        setStorageValue("lastFetch", null);
        return state.set("lastFetch", null);
      }
    case LOAD_COUNTRY_CONFIG:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryConfig", "error"], null).setIn(["countries", data.country, "countryConfig", "loading"], true);
      }
    case LOAD_COUNTRY_CONFIG_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryConfig", "loading"], false).setIn(["countries", data.country, "countryConfig", "error"], error);
      }
    case LOAD_COUNTRY_CONFIG_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          ...data.config,
          countryConfig: {
            error: null,
            loading: false,
            lastFetch: new Date()
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_CONFIG:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryConfig'] && countries[data.country]['countryConfig']['lastFetch']) {
          countries[data.country]['countryConfig']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_ALL:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionAll", "error"], null).setIn(["countries", data.country, "countryProductionAll", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_ALL_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionAll", "loading"], false).setIn(["countries", data.country, "countryProductionAll", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_ALL_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          ...data.config,
          countryProductionAll: {
            error: null,
            loading: false,
            lastFetch: new Date()
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_ALL:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionAll'] && countries[data.country]['countryProductionAll']['lastFetch']) {
          countries[data.country]['countryProductionAll']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsCampaignFlow", "error"], null).setIn(["countries", data.country, "countryProductionFarmStatsCampaignFlow", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsCampaignFlow", "loading"], false).setIn(["countries", data.country, "countryProductionFarmStatsCampaignFlow", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionFarmStatsCampaignFlow: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionFarmStatsCampaignFlow'] && countries[data.country]['countryProductionFarmStatsCampaignFlow']['lastFetch']) {
          countries[data.country]['countryProductionFarmStatsCampaignFlow']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_BOUNDARY_PRIMARY:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "error"], null).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "loading"], true);
      }
    case LOAD_COUNTRY_BOUNDARY_PRIMARY_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "loading"], false).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "error"], error);
      }
    case LOAD_COUNTRY_BOUNDARY_PRIMARY_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          ...data.config,
          countryBoundaryPrimary: {
            [data.level]: {
              error: null,
              loading: false,
              lastFetch: new Date()
            }
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "lastFetch"], new Date()).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "loading"], false).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "error"], null);
      }
    case RESET_COUNTRY_BOUNDARY_PRIMARY:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryBoundaryPrimary'] && countries[data.country]['countryBoundaryPrimary']['lastFetch']) {
          countries[data.country]['countryBoundaryPrimary']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries).setIn(["countries", data.country, "countryBoundaryPrimary", data.level, "lastFetch"], null);
      }
    case CLEAR_APP_CONFIG:
      {
        removeStorageValue("countries", prefix);
        removeStorageValue("syntheticCategories", prefix);
        removeStorageValue("startingYear", prefix);
        removeStorageValue("lastFetch", prefix);
        return state.set("countries", {}).set("syntheticCategories", {}).set("startingYear", null).set("lastFetch", null).set("loading", false).set("error", null);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "error"], null).setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "loading"], false).setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionFarmStatsFarmLand: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionFarmStatsFarmLand'] && countries[data.country]['countryProductionFarmStatsFarmLand']['lastFetch']) {
          countries[data.country]['countryProductionFarmStatsFarmLand']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "error"], null).setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "loading"], false).setIn(["countries", data.country, "countryProductionFarmStatsFarmLand", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionFarmStatsFarmPriceVariation: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionFarmStatsFarmPriceVariation'] && countries[data.country]['countryProductionFarmStatsFarmPriceVariation']['lastFetch']) {
          countries[data.country]['countryProductionFarmStatsFarmPriceVariation']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_AVG_YIELD:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionAvgYield", "error"], null).setIn(["countries", data.country, "countryProductionAvgYield", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_AVG_YIELD_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionAvgYield", "loading"], false).setIn(["countries", data.country, "countryProductionAvgYield", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_AVG_YIELD_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionAvgYield: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_AVG_YIELD:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionAvgYield'] && countries[data.country]['countryProductionAvgYield']['lastFetch']) {
          countries[data.country]['countryProductionAvgYield']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_VOLUME:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionVolume", "error"], null).setIn(["countries", data.country, "countryProductionVolume", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_VOLUME_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionVolume", "loading"], false).setIn(["countries", data.country, "countryProductionVolume", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_VOLUME_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionVolume: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_VOLUME:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionVolume'] && countries[data.country]['countryProductionVolume']['lastFetch']) {
          countries[data.country]['countryProductionVolume']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_COUNTRY_PRODUCTION_COSTS:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "countryProductionCosts", "error"], null).setIn(["countries", data.country, "countryProductionCosts", "loading"], true);
      }
    case LOAD_COUNTRY_PRODUCTION_COSTS_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "countryProductionCosts", "loading"], false).setIn(["countries", data.country, "countryProductionCosts", "error"], error);
      }
    case LOAD_COUNTRY_PRODUCTION_COSTS_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          countryProductionCosts: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_COUNTRY_PRODUCTION_COSTS:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['countryProductionCosts'] && countries[data.country]['countryProductionCosts']['lastFetch']) {
          countries[data.country]['countryProductionCosts']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_RESOURCE_CONFIG:
      {
        const {
          data
        } = action;
        return state.setIn(["countries", data.country, "resourceConfig", "error"], null).setIn(["countries", data.country, "resourceConfig", "loading"], true);
      }
    case LOAD_RESOURCE_CONFIG_ERROR:
      {
        const {
          data,
          error
        } = action;
        return state.setIn(["countries", data.country, "resourceConfig", "loading"], false).setIn(["countries", data.country, "resourceConfig", "error"], error);
      }
    case LOAD_RESOURCE_CONFIG_DONE:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        countries[data.country] = {
          ...(countries[data.country] && countries[data.country]),
          resourceConfig: {
            error: null,
            loading: false,
            lastFetch: new Date(),
            ...data.data
          }
        };
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case RESET_RESOURCE_CONFIG:
      {
        const {
          data
        } = action;
        let countries = getStorageValue("countries", {}, prefix);
        if (countries[data.country] && countries[data.country]['resourceConfig'] && countries[data.country]['resourceConfig']['lastFetch']) {
          countries[data.country]['resourceConfig']['lastFetch'] = null;
        }
        setStorageValue("countries", countries, prefix);
        return state.set("countries", countries);
      }
    case LOAD_GLOBAL_PRODUCTION_VOLUME:
      {
        return state.setIn(["globalProductionVolume", "error"], null).setIn(["globalProductionVolume", "loading"], true);
      }
    case LOAD_GLOBAL_PRODUCTION_VOLUME_ERROR:
      {
        const {
          error
        } = action;
        return state.setIn(["globalProductionVolume", "loading"], false).setIn(["globalProductionVolume", "error"], error);
      }
    case LOAD_GLOBAL_PRODUCTION_VOLUME_DONE:
      {
        const {
          data
        } = action;
        return state.setIn(["globalProductionVolume", "loading"], false).setIn(["globalProductionVolume", "error"], null).setIn(["globalProductionVolume", "config"], data.config).setIn(["globalProductionVolume", "filter"], data.filter);
      }
    case RESET_GLOBAL_PRODUCTION_VOLUME:
      {
        return state.setIn(["globalProductionVolume", "lastFetch"], null);
      }
    default:
      return state;
  }
};