import * as api from "../data/api";
import Immutable from "immutable";
import Papa from "papaparse";
const LOAD_DATA = "LOAD_DATA";
const LOAD_DATA_DONE = "LOAD_DATA_DONE";
const LOAD_DATA_ERROR = "LOAD_DATA_ERROR";
const LOAD_CATEGORIES = "LOAD_CATEGORIES";
const LOAD_CATEGORIES_DONE = "LOAD_CATEGORIES_DONE";
const LOAD_CATEGORIES_ERROR = "LOAD_CATEGORIES_ERROR";
const SET_FILTER = "SET_FILTER";
const SET_PRE_FILTER = "SET_PRE_FILTER";
const STORE_DATA = "STORE_DATA";
const UPDATE_DATA = "UPDATE_DATA";
const RESET_FILTERS = "RESET_FILTERS";
const RESET_PRE_FILTERS = "RESET_PRE_FILTERS";
const initialState = Immutable.Map({
  mode: "info"
});
export const setFilter = (type, value) => dispatch => {
  dispatch({
    type: SET_FILTER,
    param: type,
    value
  });
};
export const setPreFilter = (type, value) => dispatch => {
  dispatch({
    type: SET_PRE_FILTER,
    param: type,
    value
  });
};
export const storeData = (type, value) => dispatch => {
  dispatch({
    type: STORE_DATA,
    param: type,
    value
  });
};
export const updateData = (type, value) => (dispatch, getState) => {
  let data = getState().get("data").getIn([...type, "data"]);
  Object.keys(data).forEach(key => {
    if (data[key].constructor && data[key].constructor === Array && value[key].constructor && value[key].constructor === Array) {
      data[key] = data[key].concat(value[key]);
    }
  });
  dispatch({
    type: LOAD_DATA_DONE,
    store: type,
    data
  });
};
export const resetFilters = () => dispatch => {
  dispatch({
    type: RESET_FILTERS
  });
};
export const resetPreFilters = () => dispatch => {
  dispatch({
    type: RESET_PRE_FILTERS
  });
};
export const setData = ({
  app,
  csv,
  store,
  params
}) => (dispatch, getState) => {
  const filters = getState().get("data").getIn(["filters"]);
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  const data = Papa.parse(csv, {
    header: true
  });
  dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  });
};
export const getData = ({
  app,
  source,
  store,
  params
}) => (dispatch, getState) => {
  const filters = getState().get("data").getIn(["filters"]);
  if (filters) {
    params = {
      ...params,
      ...filters.toJS()
    };
  }
  dispatch({
    type: LOAD_DATA,
    params,
    store
  });
  api.getData({
    app,
    source,
    params
  }).then(data => dispatch({
    type: LOAD_DATA_DONE,
    store,
    data
  })).catch(error => dispatch({
    type: LOAD_DATA_ERROR,
    store,
    error
  }));
};
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA:
      {
        const {
          store
        } = action;
        return state.deleteIn([...store, "error"]).setIn([...store, "loading"], true);
      }
    case LOAD_DATA_ERROR:
      {
        const {
          error,
          store
        } = action;
        return state.setIn([...store, "loading"], false).setIn([...store, "error"], error);
      }
    case LOAD_DATA_DONE:
      {
        const {
          data,
          store
        } = action;
        return state.setIn([...store, "loading"], false).deleteIn([...store, "error"]).setIn([...store, "data"], data);
      }
    case LOAD_CATEGORIES:
      return state;
    case LOAD_CATEGORIES_DONE:
      const {
        data
      } = action;
      return state.setIn(["categories"], Immutable.fromJS(data));
    case LOAD_CATEGORIES_ERROR:
      return state;
    case SET_FILTER:
      {
        const {
          param,
          value
        } = action;
        if (value === undefined || value === null || value.constructor && value.constructor === Array && value.length === 0) {
          return state.deleteIn(["filters", param], value);
        }
        return state.setIn(["filters", param], value);
      }
    case SET_PRE_FILTER:
      {
        const {
          param,
          value
        } = action;
        if (value === undefined || value === null || value.constructor && value.constructor === Array && value.length === 0) {
          return state.deleteIn(["preFilters", param], value);
        }
        return state.setIn(["preFilters", param], value);
      }
    case UPDATE_DATA:
      {
        const {
          param,
          value
        } = action;
        if (value === undefined || value === null || value.constructor && value.constructor === Array && value.length === 0) {
          return state.deleteIn([...param], value);
        }
        let data = state.getIn([...param, "data"]);
        Object.keys(value).forEach(key => {
          if (data[key].constructor && data[key].constructor === Array && value[key].constructor && value[key].constructor === Array) {
            data[key] = data[key].concat(value[key]);
          }
        });
        return state.setIn([...param, "data"], data);
      }
    case STORE_DATA:
      {
        const {
          param,
          value
        } = action;
        if (value === undefined || value === null || value.constructor && value.constructor === Array && value.length === 0) {
          return state.deleteIn([...param], value);
        }
        return state.setIn([...param], value);
      }
    case RESET_FILTERS:
      return state.delete("filters");
    case RESET_PRE_FILTERS:
      return state.delete("preFilters");
    default:
      return state;
  }
};