import { get, post } from "./commons";
function queryParams(params) {
  return Object.keys(params).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])).join("&");
}
export const getData = ({
  source,
  app,
  params
}) => {
  return get(source + (params ? "?" + queryParams(params) : ""));
};
export const postData = ({
  source,
  app,
  params
}) => {
  return post(source, params);
};